@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


body{
    font-family: 'Montserrat', sans-serif !important;
    background-image: url("../image/texture-bg.png");
    background-position: left center;
    background-attachment: fixed;
    background-repeat: no-repeat;
}
#root{overflow: hidden;}
.nav-white {
  background-color: white;
}
.logo-img{
  width: 200px;
}
.navbar-nav .nav-link{
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-link {
    padding-left: 2.777rem !important;
    padding-right: 2.777rem !important;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    color: #030303 !important;    
    }

.btn-red {
    padding: 17.7px 39.4px 18.6px 39.4px;

    font-family: Montserrat;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #ffffff;    
    background-color: #910a3d;
}

.first-title {
    font-family: Montserrat;
    font-size: 44px;
    font-weight: 800;
    color: #313131;
  }


.paragraph {
  font-family: Helvetica;
  font-size: 16px;
  line-height: 1.8;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #313131;
  margin-bottom: 10px;
}

.col-space{
    height: 100px;
}

.col-space-2{
    height: 156px;
}

.col-space-3{
    height: 85px;
}

.col-space-4{
    height: 55px;
}

  .token-collab {
    font-family: Montserrat;
    font-size: 14px;
    text-align: left;
    color: #a3a3a3;
  }


  .scroll-down {
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 500;
    text-align: left;
    color: #313131;
    padding-right: 0.5rem;
  }

  .img-mouse{
    padding-right: 0.5rem;
    height: 50px;
  }

  .square {
    padding: 30px;
    background-color: #0072ae;
    overflow: hidden;
    max-width: 100vw;
  }

  .some-of-our-numbers {
    margin: 0 87px 8px 0;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 800;
    text-align: left;
    color: #ffffff;
  }


  .some-numbers-normal {
    font-family: Montserrat;
    font-size: 14px;
    color: #ffffff;
  }
  
  .some-numbers-bold {
    font-family: Montserrat;
    font-size: 14px;
    color: #ffffff;      
    font-weight: bold;
  }

  .about-us {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 800;
    text-align: left;
    color: #313131;
    margin-bottom: 20px;
  }

.about-us-text    {
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.8;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #313131;
    margin-bottom: 20px;
  }

  .how-it-works {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 800;
    text-align: left;
    color: #313131;
    margin-bottom: 20px;
  }

  .how-it-works-text {
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.8;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #313131;
  }

  .discover {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 800;
    text-align: left;
    color: #313131;
    margin-bottom: 20px;
  }

  .discover-text {
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.8;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #313131;
  }

  .section-gray {
    padding: 30px 15px;
    background-color: #707070;
    position: relative;
  }
  .section-gray .overlay-bg{
    position: absolute;
    width: 100%; height: 100%;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%) rotate(-10deg);
    background-color: rgba(67, 67, 67,.8);
  }

  .square-yellow {
    width: 100% !important;
    height: 364px !important;
    padding: 42.5px 43px 39px 28px !important;
    border: solid 1px #ffe399;
    background-color: #ffffff;
  }

  .div-space{
      height: 100px;
  }

  .col-squeare-yellow {
    padding-right: unset;
    padding-left: unset;

  }

  .title-yellow-square {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 800;
    text-align: left;
    color: #313131;
  }

  .text-yellow-square{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    color: #313131;    
  }

  .btn-black {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    color: #030303 !important;    
    }

    .make-it-unique {
      font-family: Montserrat;
      font-size: 44px;
      font-weight: 800;
      color: #fff;
      margin-bottom: 20px;
    }


    .make-it-unique-text{
      font-family: Helvetica;
      font-size: 16px;
      line-height: 1.8;
      color: #fff;
    }

    .get-in-touch {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 800;
      text-align: left;
      color: #313131;
      margin-bottom: 20px;
    }


    .join-our-newsletter {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 800;
      text-align: left;
      color: #313131;
    }

    .join-our-newsletter-text {
      font-family: Montserrat;
      font-size: 14px;
      text-align: left;
      color: #313131;
    }


    .footer-text {
      font-family: Montserrat;
      font-size: 14px;
      text-align: left;
      color: #313131;
    }


.video-production{
    width: 527.2px;
    height: 369.9px;
}


      /****  floating-Lable style start ****/
.floating-label { 
  position:relative; 
  margin-bottom:20px; 
}
.floating-input , .floating-select {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 20px);
  padding: .975rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: unset !important; 
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-weight: bold;
}

.floating-input:focus , .floating-select:focus {
     outline:none;
     border-bottom:2px solid #f9c846; 
}

label {
  color:#999; 
  font-size:14px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:10px;
  top:19px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

.floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
  top:3px;
  font-size:12px;
  color:#000000;
}

.floating-select:focus ~ label , .floating-select:not([value=""]):valid ~ label {
  top:3px;
  font-size:12px;
  color:#000000;
}

.img-size{
  width: 530px;
}


/* active state */
.floating-input:focus ~ .bar:before, .floating-input:focus ~ .bar:after, .floating-select:focus ~ .bar:before, .floating-select:focus ~ .bar:after {
  width:50%;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.floating-textarea {
  height: 150px;
   overflow:hidden;
  overflow-x: hidden; 
}

/* highlighter */
.highlight {
  position:absolute;
  height:50%; 
  width:100%; 
  top:15%; 
  left:0;
  pointer-events:none;
  opacity:0.5;
}


/****  floating-Lable style end ****/



#home{
  margin-top: 86px;
  position: relative;
  width: 100vw;
  height: calc(100vh - 86px);
  overflow: hidden;
}
#home .videoBg{
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  background-color: #2f0615;
  opacity: .8;
}
#home .videoCityBg{
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
}
#home .banner-img{
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%) rotate(0deg);
  width: 800px;
  max-width: 80%;
  /*animation-name: hero_logo;
  animation-duration: 1.3s;
  animation-timing-function: ease-in-out;*/
}
.first-content{
  padding: 100px 15px 50px 15px;
  overflow: hidden;
}
.logoHero{
  max-width: 100%;
}
.heroText{
  font-size: 20px;
  font-family: Montserrat;
  color:#fff;
  line-height: 1.3;
  text-align: center;
  margin-top: 20px;
}

.logo-footer{
  width: 250px;
  margin-bottom: 15px;
}
.footer-links{
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
}
.footer-links li{
  margin: 0;
}

.footer-links li a, .footer-title, .footer-text{
  color: #cbcbcb
}
.footer-links li a:hover{
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.btn:hover{
  color: #ffb728;
}

#footer-container{
  margin-top: 200px;
  background-color: #271818;
  padding: 40px 15px 20px 15px;
}
#footer-container hr{
  border-top: 1px solid rgba(255,255,255,.1);
}
.erroMsg{
  color:rgb(250, 67, 67);
  font-size: 12px
}
.successMsg{
  color:rgb(46, 179, 46);
  font-size: 12px
}
@keyframes hero_logo {
  from {opacity: 0; transform: translate(-50%,-50%) rotate(-90deg);}
  to {opacity: 1; transform: translate(-50%,-50%) rotate(0deg); }; 
}

.navbar-light .navbar-nav .nav-link{cursor: pointer;}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover{
  color: #f9c846 !important;
}
.whats-are-nft-img{
  max-width: 800px;
  width: 100%;
}
@media (min-width: 992px){
  .first-content{
    padding: 200px 0 100px 0;
  }
  .footer-links{
    justify-content: flex-end;
  }
  .footer-links li{
    margin: 3px 7px;
  }
  .footer-links li:last-child{
    margin-right: 0;
  }
  .logo-img{
    width: 268px;
  }
  #home{
    margin-top: 106px;
    height: calc(100vh - 106px);
  }
  .heroText{
    font-size: 30px;
    margin-top: 40px;
  }
  .section-gray {
    padding: 72px 169.5px 72px 129px;
  }
  .square {
    padding: 29px 57px;
    background-color: #0072ae;
  }
  .square-yellow {
    width: 318px !important;
    height: 364px !important;
    padding: 42.5px 43px 39px 28px !important;
    border: solid 1px #ffe399;
    background-color: #ffffff;
  }
}






